// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ad-1-aktuelles-klartext-js": () => import("../src/pages/ad_1_aktuelles-klartext.js" /* webpackChunkName: "component---src-pages-ad-1-aktuelles-klartext-js" */),
  "component---src-pages-ad-b-1-impressum-js": () => import("../src/pages/ad_b1_impressum.js" /* webpackChunkName: "component---src-pages-ad-b-1-impressum-js" */),
  "component---src-pages-ad-b-2-datenschutz-js": () => import("../src/pages/ad_b2_datenschutz.js" /* webpackChunkName: "component---src-pages-ad-b-2-datenschutz-js" */),
  "component---src-pages-ad-b-3-construction-js": () => import("../src/pages/ad_b3_construction.js" /* webpackChunkName: "component---src-pages-ad-b-3-construction-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

