exports.onRouteUpdate = ({ location, prevLocation }) => {
  let showSidebar = () => {

    document.getElementById("sidebar").style.setProperty('display', 'block', 'important');
    document.getElementById("hamburger").style.setProperty('display', 'none');
  }

  let attachSidebarEvent = () => {
    const hamburger = document.getElementById("hamburger");
    hamburger && hamburger.addEventListener('click', showSidebar);
  }

  attachSidebarEvent();
}